// ---------------------------------------------------------------------
//  Global Header Styles
// ---------------------------------------------------------------------


// ----------------------------------------
// Top Bar
// ----------------------------------------
body {
    padding-top: 10px;

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: $brand;
        z-index: 100;
    }
}


// ----------------------------------------
// Site header
// ----------------------------------------
.site-header {
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 10px;
    left: 0;
    z-index: 10;
    transition: opacity $trans;

    @include start-at($small) {
        padding: 25px 0;
    }

    &.is-sticky {
        opacity: 0;
        pointer-events: none;

        &.scrolling-up {
            transition-delay: 0.4s;
            opacity: 1;
            pointer-events: initial;
        }
    }
}


// ----------------------------------------
// Logo
// ----------------------------------------

#site-logo {
    width: 100%;
    order: -1;
    padding: 2px;

    a {
        display: block;
        max-width: 137px;
        width: 98%;
        margin: 0 auto;
    }

    @include start-at($header-breakpoint){
        width: 20%;
        order: initial;
    }
}


// ----------------------------------------
// Navigation
// ----------------------------------------

.site-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include start-at($header-breakpoint){
        max-width: 100%;
        width: 96%;
        margin: 0 auto;
    }
}

.site-nav ul {
    text-align: center;

    li {
        display: inline-block;
        padding: 0 12px;

        &.is-active {
            a {
                &:before {
                    transform: scaleX(1);
                }
            }
        }
    }

    a {
        position: relative;
        display: block;
        padding: 8px 0;
        font-size: 14px;
        letter-spacing: -.66px;
        transition: font-size $trans, color $trans;

        &:before {
            position: absolute;
            width: 100%;
            height: 2px;
            background: $gray-dark;
            content: '';
            bottom: 12px;
            display: block;
            left: 0;

            transform: scaleX(0);
            transition: opacity 0.4s, transform 0.4s, background 0.4s;
        }

        &:hover {
            color: $brand;

            &:before {
                background: $brand;
                transform: scaleX(1);
            }
        }

        @include start-at(480px) {
            font-size: 14px;
        }
    }

    &:first-child li:first-child a {
        padding-left: 0;
    }

    &:last-child li:last-child a {
        position: relative;
        color: $brand;
        padding-right: 0;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 100%;
            width: 17px;
            height: 12px;
            background: url('../images/svgs/arrow-right.svg') center / cover no-repeat;
            transition: transform $trans;
        }

        &:hover {
            &:after {
                transform: translate3d(4px, 0, 0);
            }
        }
    }

    @include start-at($header-breakpoint){
        width: 40%;
        order: initial;
        display: flex;

        a {
            font-size: 16px;
        }

        &:last-child {
            justify-content: flex-end;

        }

    }

    @include start-at($large){
        a {
            font-size: 20px;
        }
    }

}


@include stop-at($medium) {

    .site-nav {
        position: fixed;
        height: 100%;
        width: 50%;
        background: darken($cream, 2%);
        top: 0;
        right: -50%;
        //transform: translate3d(100%,0,0);
        z-index: 10;
        transition: right 0.4s ease-out;

        display: block;

        padding: 100px 15px;

        ul, li,
        ul li {
            display: block;
            text-align: left;

            a {
                display: inline-block;
            }
        }



        .get-started-link {
            width: 90px;
        }
    }

    #site-logo {
        position: fixed;
        top: 24px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        z-index: -1;
    }

    .menu-is-active {
        .site-nav {
            right: 0;
        }
    }
}



// ----------------------------------------
// Mobile Slide Out
// ----------------------------------------

@include stop-at($medium) {
.screen {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.2);
    z-index: 9;
    display: none;
}

.burger {
	overflow: hidden;
	position: absolute;
	z-index: 102;
	width: 44px;
	height: 44px;
    top: 16px;
    right: 12px;
	transition: all $transition;
	align-self: center;
	display: flex;

    &:hover {
        cursor: pointer;
    }
}

.burger > * {
	align-self: center;
	width: 22px;
	height: 12px;
	position: relative;
	margin: 0 auto;
	left: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);

	> * {
	   position: absolute;
	   display: block;
	   height: 2px;
	   width: 100%;
	   background-color: $black;
	   margin: 0 auto;
	   transition: all 0.6s ease-in-out;
	   -webkit-backface-visibility: hidden;
   }
}

.burger-top { top: 0; }
.burger-middle { top: 50%; margin-top: -1px; }
.burger-bottom { bottom: 0; }


// ----------------------------------------
// Active Styles
// ----------------------------------------

.menu-is-active {
	.burger-top {
		top: 50%;
		margin-top: -2px;
		transform: rotate(45deg);
	}

	.burger-middle {
		transform: rotate(-135deg);
		opacity: 0;
	}

	.burger-bottom {
		bottom: 50%;
		margin-top: 1px;
		transform: rotate(-45deg);
	}
}
}
