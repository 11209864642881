// ---------------------------------------------------------------------
//  Meal Styles
// ---------------------------------------------------------------------

.meal-preview {
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 1;

    &:hover {
        cursor: pointer;

        .meal-preview__img span,
        .meal-preview__desc:after {
                opacity: 1;
        }
    }
}

.meal-preview__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    background: $green;
    z-index: 2;
    margin-bottom: 15px;

    > span {
        width: 150px;
        height: 70px;
        font-weight: 300;
        font-size: 16px;
        color: #030303;
        letter-spacing: 0.05px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        background: $white;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.2s ease;

        span {
            position: relative;
        }

        > span:after {
            display: block;
            width: 100%;
            height: 2px;
            background: #030303;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    @include start-at($medium) {
        margin-bottom: 30px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.meal-preview__desc,
.meal__name {
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
    position: relative;
    padding-bottom: 15px;

    @include start-at($medium) {
        padding-bottom: 20px;
    }

    &:after {
        display: block;
        width: 100%;
        height: 2px;
        background: #030303;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    @include start-at($medium) {

    }
}


// ----------------------------------------
// Full meal
// ----------------------------------------

.meal {
    position: relative;
    z-index: 1;
}

.meal__img.bg-image {
    z-index: -1;

    @include stop-at($medium) {
        position: relative;
        height: 340px;
    }

    @include start-at($medium) {
        margin-left: -15%;
        width: 115%;
    }

    @include start-at($large) {
        margin-left: 0;
        width: 100%;
    }

}

.meal__name {
    margin-bottom: 15px;
    &:after {
        opacity: 1;

    }
}

.meal__content {


    @include start-at($medium) {
        max-width: 365px;
    }
}

.meal__description,
.meal__description p {
    font-family: $sans-serif;
    //font-weight: 300;
    //font-size: 12px;
    color: $black;
    //letter-spacing: 0.05px;
    //line-height: 1.5;

    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 1.5;
    font-weight: 400;
}


.meal__benefits {

    ul {
        width: 100%;

        > * {
            min-width: 100px;
            padding-right: 12px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 10px;
        }

        li div {
            display: flex;
            align-items: center;
            max-width: 105px;

            img {
                max-width: 30px;
                margin-right: 8px;
                max-height: 30px;
                width: auto;
            }

            p {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.meal__benefits-hd {
    font-family: $sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #3B3838;
    letter-spacing: 1.33px;
    line-height: 28px;
    text-transform: uppercase;
}

.benefit {
    font-family: $sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #030303;
    letter-spacing: 0.05px;
    line-height: 1.5;
    margin-bottom: 4px;
}

.benefit--hide {
    text-indent: -999999px;
}
