// ---------------------------------------------------------------------
//  SCSS Settings
// ---------------------------------------------------------------------


// ----------------------------------------
// Fonts
// ----------------------------------------

// Font weights: 400 | 700
$sans-serif: "nimbus-sans", mono, sans-serif;
$serif: 'facultadregular', Georgia, sans-serif;


// ----------------------------------------
// Font Size/ Line Height
// ----------------------------------------
$main-font: $sans-serif;
$base-font-size: 1.5rem;
$base-line-height: 1.6;


// ----------------------------------------
// Colors
// ----------------------------------------


$brand: #FE6340; // Hot Orange
$brand-bright: #FE6340;

$cream: #FCF9F4;
$cream-dark: #F8ECDC;
$green: #475A51;
$brown-light: #E0D3CB;
$brown: #BC9E7B;

$black: #030303;
$gray-dark: #3B3838;

$white: #ffffff;

$gray-light: #e7e7e7;
$gray: #666;

$error: #FF3333;


// ----------------------------------------
// Breakpoints
// ----------------------------------------

$xlarge: 1440px;
$large: 1144px;
$medium: 800px;
$small: 600px;

@mixin start-at($device-width) {
  @media (min-width: $device-width) {
    @content;
  }
}

@mixin stop-at($device-width) {
  @media (max-width: $device-width - 1) {
    @content;
  }
}


// ----------------------------------------
// Global Variables
// ----------------------------------------

$header-breakpoint: $medium;

$transition-speed: 0.4s;
$transition: $transition-speed ease;
// cuz I no type good
$trans: $transition-speed ease;

$wrapper-percentage: 90%;
$wrapper-width: 1260px;
$wrapper-padding: (100% - $wrapper-percentage) / 2;
