// ---------------------------------------------------------------------
//  Typography Styles
// ---------------------------------------------------------------------


.h1 {
    font-family: $serif;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: -0.5px;
    line-height: 1.16;

    @include start-at($medium) {
        font-size: 50px;
    }
}

.h2 {
    font-family: $serif;
    font-weight: 400;
    font-size: 32px;
    color: #3B3838;
    letter-spacing: -0.38px;
    line-height: 1.15;

    @include start-at($medium) {
        font-size: 38px;
    }

    + .h2 {
        margin-top: 25px;
    }

    + p {
        margin-top: 12px;
    }

    + .h3 {
        margin-top: 40px;
    }
}

.h3 {
    font-family: $sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3B3838;
    letter-spacing: 1.33px;
    line-height: 28px;

    + p {
        margin-top: 12px;
    }
}


p,
.p {
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 24px;
    color: #3B3838;
    letter-spacing: -0.05px;
    line-height: 1.3333333;

    @include stop-at($small) {
        font-size: 20px;
    }

    + p {
        margin-top: 12px;
    }

    + .h3 {
        margin-top: 50px;
    }
}

.p-sm {
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 1.5;
    font-weight: 400;
}


.text-content {

    .text-link {
        display: block;
        color: $brand;
        text-decoration: underline;
        transition: opacity 0.3s;

        &:hover {
            text-decoration: underline;
            opacity: 0.6;
        }
    }

    p + .text-link,
    .text-link + .text-link {
        margin-top: 15px;
    }
}
