// ---------------------------------------------------------------------
//  Media Styles
// ---------------------------------------------------------------------

.img-full {
    width: 100%;
}

.img-cover {
    object-fit: cover;
    width:100%;
    height:100%;
}

.bg-image {
    @include bg-image;
}

@include stop-at($medium) {
    .bg-min-h-sm {
        position: relative;
    }
}

.has-sm-video {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include stop-at($medium) {
        padding: 40px 0;
    }
}

.video-sm {
    max-width: 320px;
    max-height: 455px;
    width: auto;
    height: auto;

    @include stop-at($medium) {
        max-height: 255px;
    }
}

.morph-end {
    visibility: hidden;
}


.personalized-wrap {
    position: relative;

    @include start-at($large){
        padding-right: 250px;
    }

    .holder {
        opacity: 0;
    }
}

.personalization-images {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include stop-at($medium) {
        img {
            max-width: 140%;
            width: 140%;
            margin-left: -20%;
        }
    }

    li {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.is-active {
            opacity: 1;
        }
    }
}

// ----------------------------------------
// SVGs
// ----------------------------------------

svg {
	display: block;
}


// ----------------------------------------
// Media Object
// ----------------------------------------

.media-object {
    width: 100%;
    overflow: hidden;

    @include start-at($medium) {
        display: flex;
        align-items: center;

        &.media-object--equalWidth > * {
            width: 48%;

            img {
                width: 100%;
            }
        }

        &.media-object--flip .media-object__media {
            order: 2;
            margin: 0 0 0 24px;
        }
    }
}

.media-object__media {
    margin: 0 0 24px 0;

    img {
       display: block;
   }

   @include start-at($medium) {
       margin: 0 24px 0 0;
       flex-grow: 1;
       min-width: 200px;
   }
}

.media-object__bd {
    overflow: hidden;
}
