// ---------------------------------------------------------------------
//  Global Footer Styles
// ---------------------------------------------------------------------

.site-footer {
    overflow: hidden;
    background: $green;
    color: $white;

    a {
        color: $white;
    }
}

// ----------------------------------------
// Large Nav
// ----------------------------------------
.site-footer__nav {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        width: 50%;
        position: relative;
        height: 200px;
        display: flex;
        align-items: center;
        padding-left: 5%;
        transition: color $trans;

        &:hover {
            color: $black;
        }

        + a {
            &:before {
                content: '';
                height: 80%;
                width: 1px;
                background: $black;
                display: block;
                position: absolute;
                left: 0;
                top: 10px;

                @include start-at($medium) {
                    height: calc(100% - 46px);
                    top: 20px;
                }
            }
        }
    }

    @include stop-at($small) {
        .h1 {
            font-size: 20px;
            height: 120px;
        }
    }
}



// ----------------------------------------
// Footer's footer?
// ----------------------------------------

.site-footer__creds {
    border-top: 1px solid $black;
    padding: 36px 3%;

    @include start-at($small) {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        > * {
            width: 100%;
        }
    }
}

.zeus-jones {
    a {
        text-decoration: underline;
        transition: color $trans;

        &:hover {
            color: $black;
        }
    }
    img {
        display: block;
        margin-top: 10px;

        @include stop-at($small) {
            margin-bottom: 15px;
        }
    }
}


.site-footer__text {
    max-width: 345px;
    font-size: 16px;

    @include start-at($small) {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        text-align: left;
        width: 345px;
        min-width: 345px;

        > * {
            width: 100%;
        }
    }

    .d-flex {
        width: 100%;
        justify-content: space-between;
    }

    a {
        transition: color $trans;



        &:hover {
            color: $black;

            > span {
                color: $white;
            }
        }
    }
}
