// ---------------------------------------------------------------------
//  Link Styles
// ---------------------------------------------------------------------


.arrow-link {
    position: relative;
    font-family: $sans-serif;
    font-size: 16px;
    letter-spacing: 1.33px;
    text-transform: uppercase;
    display: inline-block;

    @include stop-at($small) {
        font-size: 12px;
    }

    &:before {
        content: '';
        display: block;
        position: relative;
        margin-bottom: 15px;
        width: 40px;
        height: 27px;
        background: url('../images/svgs/arrow-right-black.svg') center / cover no-repeat;
        transition: transform $trans;
    }


    &:hover {
        &:before {
            transform: translate3d(4px, 0, 0);
        }
    }
}

.arrow-link--lg {
    font-family: $serif;
    font-size: 50px;
    letter-spacing: -.5px;
    text-transform: none;

    &:before {
        margin-bottom: 16px;
    }
}

.underline-link {
    color: $brand;
    position: relative;
    padding-bottom:4px;

    &:before {
        position: absolute;
        width: 100%;
        height: 2px;
        background: $brand;
        content: '';
        bottom: 0;
        //margin-top: 4px;
        display: block;
        left: 0;

        transform: scaleX(0);
        transition: opacity 0.4s, transform 0.4s;
    }

    &:hover {
        color: $brand;

        &:before {
            transform: scaleX(1);
        }
    }
}
