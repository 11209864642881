// ---------------------------------------------------------------------
//  Slider Styles
// ---------------------------------------------------------------------


.slider-wrap {
    overflow: hidden;
    width: 100%;
    padding: 60px 0;
}

.slider__hd {
    position: relative;
    z-index: 2;
    text-align: center;
    transform: translate3d(0, 40%, 0);
}

.slider {
    width: 395px * 15;
    display: flex;
    overflow: hidden;

    > * {
        min-width: 33.3333%;
        width: 33.3333%;
        display: flex;
    }
}

.box {
    width: 25%;
    display: block;
    margin: 0 25px;
}
