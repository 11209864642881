
.terms-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;

    .panel__content {
        width: 100%;
    }
}

.terms-section__hd {
    margin-bottom: 0px;

    @include start-at($small) {
        margin-bottom: 20px;
    }

    @include start-at($medium) {
        margin-bottom: 40px;
    }
}


.icon-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;

    > * {
        padding: 0 8px;
        width: 100%;
        max-width: 245px;
        margin-bottom: 50px;
    }

    @include start-at($small) {


        > * {
            padding: 0 8px;
            width: 33%;
        }
    }

    @include start-at($medium) {
        flex-wrap: nowrap;
        justify-content: center;

        > * {
            width: calc(100% / 5);
            margin: 0 0 0;
            padding: 0 12px;
            max-width: 240px;
        }
    }

    p {
        font-weight: 400;
    }
}


.icon {
    position: relative;
    margin: 0 auto 12px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;

    @include start-at($medium) {
        height: 215px;
    }


    &:after {
        content: '';
        width: 145px;
        height: 145px;
        background: #A88D6E;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: -1;
    }
}


#icon-stacks {
    width: 90px;

    svg {
        width: 100%;
    }

    li {
        display: none;

        &.is-active {
            display: block;
        }
    }
}


#icon-days {
    width: 172px;

    circle {
        opacity: 0;

        &.is-active {
            opacity: 1;
        }
    }
}

@keyframes days {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}


#icon-plates {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background: $black;
    border-radius: 50%;

    span {
        width: 54px;
        height: 54px;
        position: absolute;
        display: block;
        background: url('../images/svgs/plate.svg') center / cover no-repeat;
    }
}

#floating-plate {
    width: 88px;
    transform-origin: top center;
    animation: sway 3.5s ease-in-out forwards infinite;
    margin-top: -60px;
}

@keyframes sway {
    0%{transform: rotate(10deg);}
	50%{transform: rotate(-5deg)}
	100%{transform: rotate(10deg);}
}

#icon-checklist {
    width: 180px;
    transform: translate3d(18px, 0, 0);
}
