// ---------------------------------------------------------------------
//  Panel Styles
// ---------------------------------------------------------------------

.panel {
    overflow: hidden;
    padding: 60px $wrapper-padding;
}


.panel--full {
    position: relative;
    z-index: 1;

    .panel__img {
        position: absolute;
        z-index: -1;
    }
}


// ----------------------------------------
// Panel Content
// ----------------------------------------

.panel__content {
    position: relative;
    z-index: 2;
}


// ----------------------------------------
// Extensions
// ----------------------------------------

.panel--chef {

    .bowl-wrap {
        position: absolute;
        z-index: -1;
        width: 134px;
        bottom: 100%;
        right: -60px;
    }

    #shape-hairpin {
        position: absolute;
        z-index: -1;
        width: 104px;
        top: 10px;
        left: 0;
        transform: translate3d(-66px, 0, 0);
    }
}



.panel--media {

    .personalized-wrap {
        margin-top: 40px;
    }

    @include stop-at($large) {
        .panel__content {
            display: flex;
            flex-wrap: wrap;
        }

        .panel--media__text {
            order: 2;
        }

        .personalized-wrap {
            order: -1;
            transform: translate3d(15px, 0, 0);
            margin-bottom: 90px;
            margin-top: -20px;

            figure {
                margin-top: 0;
            }
        }
    }



    .panel__content {
        max-width: 1440px;
        margin: 0 auto;
        width: 100%;



        @include start-at($large) {
            display: flex;
            text-align: left;
            justify-content: flex-start;
            align-items: center;

            .panel--media__text {
                position: absolute;
                z-index: 2;
                max-width: 472px;
                right: 4%;
                top: 50%;
                transform: translate3d(0, -50%, 0);
            }

        }

    }
}


// ----------------------------------------
// Panel Split
// ----------------------------------------
.panel-split {

    @include start-at($medium) {
        display: flex;
        align-items: stretch;

        > * {
            width: 50%;
        }
    }
}

.panel-split__content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 300px;
    padding: 120px 0 40px;


    @include start-at($medium) {
        padding: 40px 0;
    }
}

.panel-split__img {
    min-height: 400px;
    position: relative;
}
