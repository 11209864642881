
// ---------------------------------------------------------------------
//  Wrapper Styles (Horizontal Spacing)
// ---------------------------------------------------------------------

.containers {
    max-width: 96%;
    padding-left: $wrapper-padding;
    padding-right: $wrapper-padding;
}

.container,
.container.panel__content {
    @extend .containers;
    width: $wrapper-width;
}

.container-sm {
    width: 768px;
    @extend .containers;
}

.container-md {
    width: 960px;
    @extend .containers;
}

.container-wide {
    width: 100%;
    padding: 0 16px;
	max-width: 1440px;
	margin: 0 auto;
}

.container-full {
    width: 100%;
    overflow: hidden;
}

.wrapper-full--brand {
    background: $brand;
}

// ----------------------------------------
// Extensions
// ----------------------------------------

.container--thin {
    width: 720px;
}
