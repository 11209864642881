// ---------------------------------------------------------------------
//  Scroller Styles
// ---------------------------------------------------------------------

$split-bp: $small;

.split {
    position: relative;
    margin: 0 auto;

    @include stop-at($split-bp) {
        margin-top: 100px;
    }

    @include start-at($split-bp) {
        display: flex;
        align-items: stretch;

        > * {
            width: 50%;
        }
    }
}


// ----------------------------------------
// Right Side of the split
// ----------------------------------------

.split__right {
    padding: 60px 0 0;

    .text-content {
        width: 468px;
        max-width: 92%;
        margin: 0 auto 80px;

        &.text-content--flush-bottom {
            margin: 0 auto 0;
        }

        img {
            margin: 30px 0 20px;

            @include stop-at($split-bp) {
                + .h3.pt-6 {
                    padding-top: 20px !important;
                }
            }
        }
    }

    @include start-at($split-bp) {
        padding: 190px 0 0;

        img {
            display: none;
        }
    }
}


// ----------------------------------------
// Left Side of the Split
// ----------------------------------------

.split__left {
    position: relative;

    @include stop-at($small) {
        display: none;

        &.split__left--show-sm {
            display: block;

        }
    }

    @include start-at($split-bp) {

        // ----------------------------------------
        // Story Images
        // ----------------------------------------

        .story-image-1 {
            position: relative;
            position: sticky;
            top: 0;

            .bg-image {
                position: relative;
                height: 100vh;
            }

        }

        .story-image-2 {
            position: sticky;
            top: 300px;

            img {
                width: 600px;
            }
        }

        .story-image-3 {
            position: sticky;
            top: 100%;
            padding-bottom: 180px;
            text-align: right;

            img {
                width: 560px;
                display: inline-block;
                vertical-align: middle;
            }
        }


        // ----------------------------------------
        // The Farm Images
        // ----------------------------------------

        .farm-image-1 {
            position: sticky;
            top: 0;
            padding-top: 230px;

        }

        .farm-image-2 {
            position: sticky;
            padding-top: 170px;
            top: 0;
            text-align: right;
            padding-bottom: 180px;

            img {
                width: 356px;
                display: inline-block;
                vertical-align: middle;
            }
        }
        .spacer {
            // //margin-top: 500px;
            // display: block;
            // //height: 200px;
            // background: red;
            // margin-bottom: -500px;
            // position: relative;
            // z-index: 10;
            display: block;
            height: 240px;
        }

        .farm-image-3 {
            position: sticky;
            padding: 0;
            //top: 100%;
            top: 0;
            height: 100vh;
            display: flex;
            align-items: flex-end;

            img {
                width: 548px;
                display: inline-block;
                vertical-align: middle;
            }
        }


        // ----------------------------------------
        // Made For You Images
        // ----------------------------------------

        .mfy-spacer {
            height: 300px;
            display: block;
        }

        .mfy-image-1 {
            position: relative;
            position: sticky;
            top: 0;

            .bg-image {
                position: relative;
                height: 100vh;
            }
        }

        .mfy-image-2 {
            position: sticky;
            padding-top: 170px;
            top: 0;
            //padding-bottom: 180px;

            img {
                width: 50%;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .mfy-image-3 {
            position: sticky;
            padding: 0;
            top: 100%;
            text-align: center;
            padding-bottom: 137px;
            //transform: translate3d(0, -100%, 0);

            img {
                width: 75%;
                display: inline-block;
                vertical-align: middle;
            }
        }

    }
}


// ----------------------------------------
// Ono Block
// ----------------------------------------

.ono-block {
    min-height: 200px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $cream;
    position: sticky;
    top: 0;

    svg {
        display: block;
        width: 185px;
        height: 52px;
        position: relative;
        z-index: 2;
    }

    @include start-at($split-bp) {
        height: 100vh;
        min-height: 100vh;
        padding-top: 0;
    }
}

.ono-block-error {
    svg {
        width: 220px;
        height: auto;
        margin: 0 auto 24px;
    }
}

@include start-at($split-bp) {
    .get-in-touch-spacer {
        height: 500px;
    }
}

.get-in-touch {
    position: sticky;
    z-index: 2;
    top: 0;

    .text-content {
        width: 468px;
        max-width: 92%;
        margin: 0 auto 80px;

        &.text-content--flush-bottom {
            margin: 0 auto 0;
        }
    }
}


.split-cta {
    background: $brand;
    padding: 95px 0;

    .arrow-link--lg {
        color: $white;
    }

    .text-content {
        margin-bottom: 0;
    }
}
