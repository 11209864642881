// ---------------------------------------------------------------------
//  Layout
// ---------------------------------------------------------------------

.section-full {
    min-height: 100vh;
    overflow: hidden;

    @media (max-height: 500px) {
        min-height: 500px;
    }

    @include stop-at($medium) {
        min-height: 568px;
    }
}

@include stop-at($medium) {
    .section-full--flip--sm {
        display: flex;
        flex-wrap: wrap;

        > *:first-child {
            order: 2;
        }
        > *:last-child {
            order: -1;
        }
    }
}

.section {
    padding: 60px 0;

    @include start-at($medium) {
        padding: 90px 0;
    }
}

.section-x-tall {
    padding: 100px 0;

    @include start-at($medium) {
        padding: 180px 0;
    }
}


// ----------------------------------------
// Padding/ Margin Add-ons
// ----------------------------------------

@mixin spacing($property, $size) {
    #{$property}: $size - 16px;

    @include breakpoint(md) {
        #{$property}: $size;
    }

}

.mt-7 {
	@include spacing(margin-top, 52px);
}
.mb-7 {
	@include spacing(margin-bottom, 52px);
}

.pt-7 {
	@include spacing(padding-top, 52px);
}

.pb-7 {
	@include spacing(padding-bottom, 52px);
}

.mt-8 {
	@include spacing(margin-top, 64px);
}
.mb-8 {
	@include spacing(margin-bottom, 64px);
}

.pt-8 {
	@include spacing(padding-top, 64px);
}

.pb-8 {
	@include spacing(padding-bottom, 64px);
}

.mt-9 {
	@include spacing(margin-top, 96px);
}
.mb-9 {
	@include spacing(margin-bottom, 96px);
}

.pt-9 {
	@include spacing(padding-top, 96px);
}

.pb-9 {
	@include spacing(padding-bottom, 96px);
}

.col {
    width: 100%;
}
