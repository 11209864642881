// ---------------------------------------------------------------------
//  Animations
// ---------------------------------------------------------------------


[data-animate-in="up"] {
	transform: translate3d(0, 24px, 0);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.in-view {
        opacity: 1;
    	transform: none;
    }
}

[data-animate-in="left"] {
    opacity: 0;
	transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.in-view {
        opacity: 1;
    	transform: none;
    }
}

[data-animate-in="right"] {
	transform: translate3d(50px, 0, 0);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.in-view {
        opacity: 1;
    	transform: none;
    }
}

[data-animate-in="down"] {
	transform: translate3d(0, -24px, 0);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.in-view {
        opacity: 1;
    	transform: none;
    }
}

[data-animate-in="fade"] {
	transform: translate3d(0, 0, 0);
    opacity: 0;
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;

    &.in-view {
        opacity: 1;
    }
}


[data-animate-in="fade-fast"] {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.in-view {
        opacity: 1;
    }
}


// ----------------------------------------
// Animate In
// ----------------------------------------








// ----------------------------------------
// Flipper
// ----------------------------------------

.flipper {
    position: relative;
    width: 500px;
    max-width: 96%;
    margin: 0 auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #030303;

    > * {
        //opacity: 0;
        position: absolute;
        width: 100%;
        transform-origin:  center;
        transform-style: preserve-3d;
        transition: opacity 0.6s, transform 1s ease;
        //transform: rotateX(89deg);
		opacity: 0;

		//-webkit-transform: rotateX(90deg);
		// transform: rotateX(90deg);
		//
		//
		//
        // span {
        //     transform: rotateX(-90deg) translateZ(-100px);
		// 	transition: all 0.6s;
        // }

		&:before {
			content: '';
			display: block;
			@include cover-parent;
			background: $cream;
			z-index: 2;
			transition: transform 0.6s ease-out;
			transform-origin: right;
		}

        &.is-active {
			opacity: 1;

			&:before {
				transform: scaleX(0);
				transition-delay: 0.4s;
			}
			// animation: flipper 2s 1;
			//
			// span {
			// 	animation: flipper-sm 2s 1;
			// }
            // opacity: 1;
            // transform: rotateX(0);
			//
            // span {
            //     transform: rotateX(0) translateZ(0);
            // }
			//
			// + li {
			// 	transform: rotateX(-89deg);
			// 	span {
			// 		transform: rotateX(90deg) translateZ(-50px);
			// 	}
			// }
        }
    }
}

@keyframes flipper {
	0% {
		transform: rotateX(-89deg);
	}
	10% {
		transform: rotateX(0);
	}
	90% {
		transform: rotateX(0);
	}
	100% {
		transform: rotateX(89deg);
	}
}

@keyframes flipper-sm {
	0% {
		transform: rotateX(-90deg) translateZ(-100px);
	}
	10%, 90% {
		transform: rotateX(0) translateZ(0);
	}
	90% {
		transform: rotateX(0) translateZ(0);
	}
	100% {
		transform: rotateX(-90deg) translateZ(-100px);
	}
}



// ----------------------------------------
// Looper
// ----------------------------------------

.looper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
    min-height: 400px;

    li {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        // opacity: 0;
        // transition: opacity 0.4s ease;


        &.is-active {
            z-index: 2;
            opacity: 1;
        }
    }
}
