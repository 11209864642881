// ---------------------------------------------------------------------
//  Utility Classes
// ---------------------------------------------------------------------

.has-accent {

    &:after {
        content: "";
        width: 100px;
        height: 2px;
        display: block;
        background: $brand;
        margin: 20px auto;
    }
}

.rel-pos {
    position: relative;
    overflow: hidden;
}

// ----------------------------------------
// Text
// ----------------------------------------

.text-center {
    text-align: center;

    p, img, .text-thin {
        margin-left: auto;
        margin-right: auto;
    }
}

.text-thin {
    max-width: 560px;
}

.text-brand {
    color: $brand;
}

.text-white {
    color: $white;
}

.text-light {
    color: $gray;
}

.text-brown {
    color: $brown;
}

.text-italic {
    font-style: italic;
}

.text-bold {
    font-weight: bold;
}

.tw-500 {
    max-width: 500px;
}


// ----------------------------------------
// Backgrounds
// ----------------------------------------

.bg-brand {
    background: $brand;
}

.bg-light {
    background: $gray-light;
}

.bg-green {
    background: $green;
}

.bg-brown-light {
    background: $brown-light;
}

.bg-pink {
    background: #F3E8E6;
}

.bg-brown {
    background: $brown;
}

.bg-cream-dark {
    background: $cream-dark;
}


.sr-only,
.screen-reader-text {
    @include visually-hidden;
}

@include stop-at($small) {
    .hide-before-sm {
        display: none;
    }
}
